import { css } from '@emotion/react'

const GlobalStyle = css`
    body {
        margin: 0;
        padding: 0;
    }
`

export default GlobalStyle
