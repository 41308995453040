/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import { Global, ThemeProvider } from '@emotion/react'
import 'intro.js/introjs.css'
import { PostHogProvider } from 'posthog-js/react'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import { DefaultTheme } from './src/DefaultTheme'
import GlobalStyle from './src/GlobalStyle'

const options = {
    api_host: process.env.GATSBY_POSTHOG_HOST,
}

export const wrapRootElement = ({ element }) => {
    return (
        <PostHogProvider apiKey={process.env.GATSBY_POSTHOG_API} options={options}>
            <ThemeProvider theme={DefaultTheme}>
                <Global styles={GlobalStyle} />
                <ReactNotifications />
                {element}
            </ThemeProvider>
        </PostHogProvider>
    )
}
