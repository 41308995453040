import { Theme } from '@emotion/react'

const BREAKPOINTS = {
    xs: 0,
    'extra-small': 0,
    sm: 600,
    small: 600,
    md: 900,
    medium: 900,
    lg: 1200,
    large: 1200,
    xl: 1536,
    'extra-large': 1536,
    tablet: 1024,
    smartphone: 768,
}

export interface ColorPalette {
    brand: {
        primary: {
            100: string
            200: string
            300: string
            400: string
            500: string
            600: string
            700: string
            800: string
            900: string
        }
        secondary: {
            100: string
            200: string
            300: string
            400: string
            500: string
            600: string
            700: string
            800: string
            900: string
        }
    }
    semantic: {
        success: {
            100: string
            200: string
            300: string
            400: string
            500: string
            600: string
            700: string
            800: string
            900: string
        }
        info: {
            100: string
            200: string
            300: string
            400: string
            500: string
            600: string
            700: string
            800: string
            900: string
        }
        warning: {
            100: string
            200: string
            300: string
            400: string
            500: string
            600: string
            700: string
            800: string
            900: string
        }
        error: {
            100: string
            200: string
            300: string
            400: string
            500: string
            600: string
            700: string
            800: string
            900: string
        }
    }
    neutral: {
        slate: {
            100: string
            200: string
            300: string
            400: string
            500: string
            600: string
            700: string
            800: string
            900: string
        }
        salt: {
            60: string
            80: string
            100: string
        }
        pepper: {
            60: string
            80: string
            100: string
        }
    }
}

declare module '@emotion/react' {
    interface Theme {
        breakpoints: {
            xs: number
            'extra-small': number
            sm: number
            small: number
            md: number
            medium: number
            lg: number
            large: number
            xl: number
            'extra-large': number
            tablet: number
            smartphone: number
            up: (screenSize: number) => string
            down: (screenSize: number) => string
        }
        spacing: (...args: number[]) => string
        color: ColorPalette
        inputs: {
            default: {
                background: string
                foreground: string
            }
        }
        common: {
            error: string
            success: string
        }
        alertBar: {
            info: {
                background: string
                foreground: string
                icon: string
            }
            success: {
                background: string
                foreground: string
                icon: string
            }
            error: {
                background: string
                foreground: string
                icon: string
            }
        }
        buttons: {
            primary: {
                backgroundLight: string
                background: string
                foreground: string
            }
            secondary: {
                background: string
                foreground: string
            }
            disabled: {
                background: string
                foreground: string
            }
        }
        fonts: {
            default: string
            body: string
        }
        sections: {
            notFound: {
                background: string
                foreground: string
            }
            search: {
                foreground: string
                hoverLink: string
            }
            serviceList: {
                background: string
                foreground: string
                serviceTitle: string
            }
            servicePresentation: {
                background: string
                backgroundLight: string
                foreground: string
                item: {
                    backgroundDark: string
                    backgroundLight: string
                }
            }
            serviceDetail: {
                summaryBackground: string
                summaryBorder: string
                background: string
                foreground: string
                stepTitle: string
                caseTitle: string
                stepAnchor: string
                stepBackground: string
            }
            servicePersona: {
                backgroundLight: string
                backgroundDark: string
                foreground: string
                foregroundDark: string
            }
            homePersona: {
                background: string
                foreground: string
            }
            serviceUnpopular: {
                backgroundLight: string
                backgroundDark: string
                foregroundLight: string
                foregroundDark: string
                item: {
                    backgroundDark: string
                    backgroundLight: string
                    foregroundBadge: string
                    backgroundBadge: string
                }
            }
            servicePopular: {
                backgroundDark: string
                item: {
                    backgroundDark: string
                    backgroundLight: string
                    foregroundLight: string
                    foregroundDark: string
                    foreGroundDefault: string
                }
            }
            serviceRequest: {
                backgroundDark: string
                foregroundDark: string
                backgroundButton: string
                foregroundLight: string
            }
            serviceMinistries: {
                background: string
                foreground: string
                backgroundButton: string
                foregroundButton: string
                item: {
                    background: string
                    borderDark: string
                    borderLight: string
                }
            }
            sectionMinistryPresentation: {
                background: string
                foreground: string
                item: {
                    backgroundDefault: string
                    backgroundHover: string
                    backgroundVisited: string
                }
            }
            sectionMinistriesGroup: {
                background: string
                foreground: string
                item: {
                    background: string
                    borderDark: string
                    borderLight: string
                }
            }
        }
    }
}

export const DefaultTheme: Theme = {
    breakpoints: {
        ...BREAKPOINTS,
        up: (screenSize: number) => {
            return `@media (min-width: ${screenSize}px)`
        },
        down: (screenSize: number) => {
            return `@media (max-width: ${screenSize}px)`
        },
    },
    spacing: (...args: number[]): string => {
        const spacingFactor = 8
        return args.map((argItm) => `${argItm * spacingFactor}px`).join(' ')
    },
    color: {
        brand: {
            primary: {
                100: '#CAFCDF',
                200: '#97FAC9',
                300: '#62F0B7',
                400: '#3AE1AF',
                500: '#03CEA4',
                600: '#02B19D',
                700: '#019491',
                800: '#006E77',
                900: '#005162',
            },
            secondary: {
                100: '#DDFAEF',
                200: '#BCF5E6',
                300: '#94E2D4',
                400: '#70C6BE',
                500: '#44A1A0',
                600: '#31838A',
                700: '#226673',
                800: '#154B5D',
                900: '#0D374D',
            },
        },
        semantic: {
            success: {
                100: '#D8FCD5',
                200: '#ACF9AE',
                300: '#7FED8D',
                400: '#5CDC79',
                500: '#2DC65E',
                600: '#20AA5A',
                700: '#168E54',
                800: '#0E724C',
                900: '#085F46',
            },
            info: {
                100: '#D0EDFF',
                200: '#A1D7FF',
                300: '#72BDFF',
                400: '#4EA5FF',
                500: '#147DFF',
                600: '#0E60DB',
                700: '#0A47B7',
                800: '#063293',
                900: '#03237A',
            },
            warning: {
                100: '#FFF7CC',
                200: '#FFED99',
                300: '#FFE066',
                400: '#FFD33F',
                500: '#FFBF00',
                600: '#DB9E00',
                700: '#B77F00',
                800: '#936300',
                900: '#7A4E00',
            },
            error: {
                100: '#FFE6D6',
                200: '#FFC7AE',
                300: '#FFA185',
                400: '#FF7D67',
                500: '#FF4235',
                600: '#DB262A',
                700: '#B71A2A',
                800: '#931029',
                900: '#7A0A28',
            },
        },
        neutral: {
            slate: {
                100: '#CFD8DC',
                200: '#B0BEC5',
                300: '#90A4AE',
                400: '#78909C',
                500: '#607D8B',
                600: '#546E7A',
                700: '#455A64',
                800: '#37474F',
                900: '#263238',
            },
            salt: {
                60: 'rgba(255, 255, 255, 0.6)',
                80: 'rgba(255, 255, 255, 0.8)',
                100: 'rgba(255, 255, 255, 1)',
            },
            pepper: {
                60: 'rgba(0, 0, 0, 0.6)',
                80: 'rgba(0, 0, 0, 0.8)',
                100: 'rgba(0, 0, 0, 1)',
            },
        },
    },
    alertBar: {
        info: {
            background: '#153B50',
            foreground: '#FFFFFF',
            icon: '#FF7467',
        },
        success: {
            background: '#00c853',
            foreground: '#FFFFFF',
            icon: '#FFFFFF',
        },
        error: {
            background: '#d32f2f',
            foreground: '#FFFFFF',
            icon: '#FFFFFF',
        },
    },
    inputs: {
        default: {
            background: '#E5E5E5',
            foreground: '#000000',
        },
    },
    buttons: {
        primary: {
            backgroundLight: '#153B50',
            background: '#03CEA4',
            foreground: '#0D374D',
        },
        secondary: {
            background: '#0D374D',
            foreground: '#ffffff',
        },
        disabled: {
            background: '#D3D3D3',
            foreground: '#000000',
        },
    },
    fonts: {
        default: ['Plus Jakarta Sans', 'sans-serif'].join(','),
        body: ['Noto Sans', 'sans-serif'].join(','),
    },
    common: {
        error: '#d32f2f',
        success: '#00c853',
    },
    sections: {
        notFound: {
            background: '#44A1A0',
            foreground: '#ffffff',
        },
        search: {
            foreground: '#153B50',
            hoverLink: '#44A1A0',
        },
        serviceList: {
            background: '#ffffff',
            foreground: '#000000',
            serviceTitle: '#222C44',
        },
        serviceDetail: {
            summaryBackground: '#F8F8F8',
            summaryBorder: '#D3D3D3',
            background: '#ffffff',
            foreground: '#000000',
            stepTitle: '#153B50',
            caseTitle: '#44A1A0',
            stepAnchor: '#1D1D1D',
            stepBackground: '#F8F8F8',
        },
        servicePresentation: {
            background: '#44A1A0',
            backgroundLight: '#ffffff',
            foreground: '#ffffff',
            item: {
                backgroundDark: '#03CEA4',
                backgroundLight: '#F8F8F8',
            },
        },
        servicePersona: {
            backgroundLight: '#03CEA4',
            backgroundDark: '#153B50',
            foreground: '#ffffff',
            foregroundDark: '#000000',
        },
        homePersona: {
            background: '#ffffff',
            foreground: '#000000',
        },
        serviceUnpopular: {
            backgroundDark: '#153B50',
            backgroundLight: '#F8F8F8',
            foregroundLight: '#FFFFFF',
            foregroundDark: '#153B50',
            item: {
                backgroundDark: '#03CEA4',
                backgroundLight: '#44A1A0',
                foregroundBadge: '#FFFFFF',
                backgroundBadge: '#153B50',
            },
        },
        servicePopular: {
            backgroundDark: '#F0F0F0',
            item: {
                backgroundLight: '#FFFFFF',
                backgroundDark: '#153B50',
                foregroundLight: '#FFFFFF',
                foregroundDark: '#43BC9C',
                foreGroundDefault: '#222C44',
            },
        },
        serviceRequest: {
            backgroundDark: '#F8F8F8',
            backgroundButton: '#153B50',
            foregroundDark: '#222C44',
            foregroundLight: '#FFFFFF',
        },
        serviceMinistries: {
            background: '#E5E5E5',
            foreground: '#000000',
            backgroundButton: '#03CEA4',
            foregroundButton: '#FFFFFF',
            item: {
                background: '#FFFFFF',
                borderLight: '#03CEA4',
                borderDark: '#153B50',
            },
        },
        sectionMinistryPresentation: {
            background: '#F8F8F8',
            foreground: '#FFFFFF',
            item: {
                backgroundDefault: '#44A1A0',
                backgroundHover: '#03CEA4',
                backgroundVisited: '#153B50',
            },
        },
        sectionMinistriesGroup: {
            background: '#FFFFFF',
            foreground: '#000000',
            item: {
                background: '#F8F8F8',
                borderLight: '#03CEA4',
                borderDark: '#153B50',
            },
        },
    },
}

export default DefaultTheme
