// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apropos-tsx": () => import("./../../../src/pages/apropos.tsx" /* webpackChunkName: "component---src-pages-apropos-tsx" */),
  "component---src-pages-contact-components-my-select-tsx": () => import("./../../../src/pages/contact/components/MySelect.tsx" /* webpackChunkName: "component---src-pages-contact-components-my-select-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-home-home-information-home-information-tsx": () => import("./../../../src/pages/home/HomeInformation/HomeInformation.tsx" /* webpackChunkName: "component---src-pages-home-home-information-home-information-tsx" */),
  "component---src-pages-home-home-list-step-service-home-list-step-service-tsx": () => import("./../../../src/pages/home/HomeListStepService/HomeListStepService.tsx" /* webpackChunkName: "component---src-pages-home-home-list-step-service-home-list-step-service-tsx" */),
  "component---src-pages-home-home-list-step-service-index-ts": () => import("./../../../src/pages/home/HomeListStepService/index.ts" /* webpackChunkName: "component---src-pages-home-home-list-step-service-index-ts" */),
  "component---src-pages-home-home-ministries-institutions-section-home-ministries-institutions-section-tsx": () => import("./../../../src/pages/home/HomeMinistriesInstitutionsSection/HomeMinistriesInstitutionsSection.tsx" /* webpackChunkName: "component---src-pages-home-home-ministries-institutions-section-home-ministries-institutions-section-tsx" */),
  "component---src-pages-home-home-ministries-institutions-section-index-ts": () => import("./../../../src/pages/home/HomeMinistriesInstitutionsSection/index.ts" /* webpackChunkName: "component---src-pages-home-home-ministries-institutions-section-index-ts" */),
  "component---src-pages-home-home-ministries-institutions-section-item-ministry-institution-index-ts": () => import("./../../../src/pages/home/HomeMinistriesInstitutionsSection/ItemMinistryInstitution/index.ts" /* webpackChunkName: "component---src-pages-home-home-ministries-institutions-section-item-ministry-institution-index-ts" */),
  "component---src-pages-home-home-ministries-institutions-section-item-ministry-institution-item-ministry-institution-tsx": () => import("./../../../src/pages/home/HomeMinistriesInstitutionsSection/ItemMinistryInstitution/ItemMinistryInstitution.tsx" /* webpackChunkName: "component---src-pages-home-home-ministries-institutions-section-item-ministry-institution-item-ministry-institution-tsx" */),
  "component---src-pages-home-home-request-section-home-request-section-tsx": () => import("./../../../src/pages/home/HomeRequestSection/HomeRequestSection.tsx" /* webpackChunkName: "component---src-pages-home-home-request-section-home-request-section-tsx" */),
  "component---src-pages-home-home-request-section-index-ts": () => import("./../../../src/pages/home/HomeRequestSection/index.ts" /* webpackChunkName: "component---src-pages-home-home-request-section-index-ts" */),
  "component---src-pages-home-home-service-new-section-home-service-new-section-tsx": () => import("./../../../src/pages/home/HomeServiceNewSection/HomeServiceNewSection.tsx" /* webpackChunkName: "component---src-pages-home-home-service-new-section-home-service-new-section-tsx" */),
  "component---src-pages-home-home-service-new-section-index-ts": () => import("./../../../src/pages/home/HomeServiceNewSection/index.ts" /* webpackChunkName: "component---src-pages-home-home-service-new-section-index-ts" */),
  "component---src-pages-home-home-service-new-section-item-new-service-index-ts": () => import("./../../../src/pages/home/HomeServiceNewSection/ItemNewService/index.ts" /* webpackChunkName: "component---src-pages-home-home-service-new-section-item-new-service-index-ts" */),
  "component---src-pages-home-home-service-new-section-item-new-service-item-new-service-tsx": () => import("./../../../src/pages/home/HomeServiceNewSection/ItemNewService/ItemNewService.tsx" /* webpackChunkName: "component---src-pages-home-home-service-new-section-item-new-service-item-new-service-tsx" */),
  "component---src-pages-home-home-service-popular-section-home-service-popular-section-tsx": () => import("./../../../src/pages/home/HomeServicePopularSection/HomeServicePopularSection.tsx" /* webpackChunkName: "component---src-pages-home-home-service-popular-section-home-service-popular-section-tsx" */),
  "component---src-pages-home-home-service-popular-section-index-ts": () => import("./../../../src/pages/home/HomeServicePopularSection/index.ts" /* webpackChunkName: "component---src-pages-home-home-service-popular-section-index-ts" */),
  "component---src-pages-home-home-service-popular-section-item-popular-service-index-ts": () => import("./../../../src/pages/home/HomeServicePopularSection/ItemPopularService/index.ts" /* webpackChunkName: "component---src-pages-home-home-service-popular-section-item-popular-service-index-ts" */),
  "component---src-pages-home-home-service-popular-section-item-popular-service-item-popular-service-tsx": () => import("./../../../src/pages/home/HomeServicePopularSection/ItemPopularService/ItemPopularService.tsx" /* webpackChunkName: "component---src-pages-home-home-service-popular-section-item-popular-service-item-popular-service-tsx" */),
  "component---src-pages-home-home-service-unpopular-section-home-service-unpopular-section-tsx": () => import("./../../../src/pages/home/HomeServiceUnpopularSection/HomeServiceUnpopularSection.tsx" /* webpackChunkName: "component---src-pages-home-home-service-unpopular-section-home-service-unpopular-section-tsx" */),
  "component---src-pages-home-home-service-unpopular-section-index-ts": () => import("./../../../src/pages/home/HomeServiceUnpopularSection/index.ts" /* webpackChunkName: "component---src-pages-home-home-service-unpopular-section-index-ts" */),
  "component---src-pages-home-home-service-unpopular-section-item-service-unpopular-index-ts": () => import("./../../../src/pages/home/HomeServiceUnpopularSection/ItemServiceUnpopular/index.ts" /* webpackChunkName: "component---src-pages-home-home-service-unpopular-section-item-service-unpopular-index-ts" */),
  "component---src-pages-home-home-service-unpopular-section-item-service-unpopular-item-service-unpopular-tsx": () => import("./../../../src/pages/home/HomeServiceUnpopularSection/ItemServiceUnpopular/ItemServiceUnpopular.tsx" /* webpackChunkName: "component---src-pages-home-home-service-unpopular-section-item-service-unpopular-item-service-unpopular-tsx" */),
  "component---src-pages-home-modal-change-language-modal-change-language-tsx": () => import("./../../../src/pages/home/ModalChangeLanguage/ModalChangeLanguage.tsx" /* webpackChunkName: "component---src-pages-home-modal-change-language-modal-change-language-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-result-tsx": () => import("./../../../src/pages/searchResult.tsx" /* webpackChunkName: "component---src-pages-search-result-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-service-categ-section-categ-item-categ-item-tsx": () => import("./../../../src/pages/services/ServiceCategSection/CategItem/CategItem.tsx" /* webpackChunkName: "component---src-pages-services-service-categ-section-categ-item-categ-item-tsx" */),
  "component---src-pages-services-service-categ-section-categ-item-index-ts": () => import("./../../../src/pages/services/ServiceCategSection/CategItem/index.ts" /* webpackChunkName: "component---src-pages-services-service-categ-section-categ-item-index-ts" */),
  "component---src-pages-services-service-categ-section-index-ts": () => import("./../../../src/pages/services/ServiceCategSection/index.ts" /* webpackChunkName: "component---src-pages-services-service-categ-section-index-ts" */),
  "component---src-pages-services-service-categ-section-service-categ-section-tsx": () => import("./../../../src/pages/services/ServiceCategSection/ServiceCategSection.tsx" /* webpackChunkName: "component---src-pages-services-service-categ-section-service-categ-section-tsx" */),
  "component---src-pages-services-service-detail-section-index-ts": () => import("./../../../src/pages/services/ServiceDetailSection/index.ts" /* webpackChunkName: "component---src-pages-services-service-detail-section-index-ts" */),
  "component---src-pages-services-service-detail-section-service-content-index-ts": () => import("./../../../src/pages/services/ServiceDetailSection/ServiceContent/index.ts" /* webpackChunkName: "component---src-pages-services-service-detail-section-service-content-index-ts" */),
  "component---src-pages-services-service-detail-section-service-content-service-content-tsx": () => import("./../../../src/pages/services/ServiceDetailSection/ServiceContent/ServiceContent.tsx" /* webpackChunkName: "component---src-pages-services-service-detail-section-service-content-service-content-tsx" */),
  "component---src-pages-services-service-detail-section-service-detail-section-tsx": () => import("./../../../src/pages/services/ServiceDetailSection/ServiceDetailSection.tsx" /* webpackChunkName: "component---src-pages-services-service-detail-section-service-detail-section-tsx" */),
  "component---src-pages-services-service-detail-section-service-summary-index-ts": () => import("./../../../src/pages/services/ServiceDetailSection/ServiceSummary/index.ts" /* webpackChunkName: "component---src-pages-services-service-detail-section-service-summary-index-ts" */),
  "component---src-pages-services-service-detail-section-service-summary-service-summary-tsx": () => import("./../../../src/pages/services/ServiceDetailSection/ServiceSummary/ServiceSummary.tsx" /* webpackChunkName: "component---src-pages-services-service-detail-section-service-summary-service-summary-tsx" */),
  "component---src-pages-services-service-list-details-section-index-ts": () => import("./../../../src/pages/services/ServiceListDetailsSection/index.ts" /* webpackChunkName: "component---src-pages-services-service-list-details-section-index-ts" */),
  "component---src-pages-services-service-list-details-section-service-list-derails-section-tsx": () => import("./../../../src/pages/services/ServiceListDetailsSection/ServiceListDerailsSection.tsx" /* webpackChunkName: "component---src-pages-services-service-list-details-section-service-list-derails-section-tsx" */),
  "component---src-pages-services-service-list-section-index-ts": () => import("./../../../src/pages/services/ServiceListSection/index.ts" /* webpackChunkName: "component---src-pages-services-service-list-section-index-ts" */),
  "component---src-pages-services-service-list-section-service-item-index-ts": () => import("./../../../src/pages/services/ServiceListSection/ServiceItem/index.ts" /* webpackChunkName: "component---src-pages-services-service-list-section-service-item-index-ts" */),
  "component---src-pages-services-service-list-section-service-item-service-item-tsx": () => import("./../../../src/pages/services/ServiceListSection/ServiceItem/ServiceItem.tsx" /* webpackChunkName: "component---src-pages-services-service-list-section-service-item-service-item-tsx" */),
  "component---src-pages-services-service-list-section-service-list-section-tsx": () => import("./../../../src/pages/services/ServiceListSection/ServiceListSection.tsx" /* webpackChunkName: "component---src-pages-services-service-list-section-service-list-section-tsx" */),
  "component---src-pages-services-service-pres-section-index-ts": () => import("./../../../src/pages/services/ServicePresSection/index.ts" /* webpackChunkName: "component---src-pages-services-service-pres-section-index-ts" */),
  "component---src-pages-services-service-pres-section-service-pres-section-tsx": () => import("./../../../src/pages/services/ServicePresSection/ServicePresSection.tsx" /* webpackChunkName: "component---src-pages-services-service-pres-section-service-pres-section-tsx" */),
  "component---src-pages-services-strapi-service-category-category-slug-tsx": () => import("./../../../src/pages/services/{strapiServiceCategory.categorySlug}.tsx" /* webpackChunkName: "component---src-pages-services-strapi-service-category-category-slug-tsx" */),
  "component---src-pages-services-strapi-service-service-slug-tsx": () => import("./../../../src/pages/services/{strapiService.serviceSlug}.tsx" /* webpackChunkName: "component---src-pages-services-strapi-service-service-slug-tsx" */)
}

